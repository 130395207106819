import './Module.scss';

import React, {ReactNode} from 'react';

interface ModuleProps {
    align?: 'left' | 'center' | 'right';
    bordered?: boolean;
    children: ReactNode;
    spacing?: 'l' | 'xl' | 'xxl' | '3xl' | '4xl' | '5xl';
    horizontal?: boolean;
    padded?: boolean;

  backgroundColor?: string,
}

export function Module({
                            padded,
                           align = 'left',
                           bordered,
                           children,
                           spacing = '4xl',
                           horizontal,
                           backgroundColor
                       }: ModuleProps) {
    return (
        <div style={{backgroundColor: backgroundColor ? backgroundColor : ''}}
            className={`pr-module pr-module--spacing-${spacing} ${
              padded ? 'padded' : ''
              } ${
                bordered ? 'pr-module--bordered' : ''
            } ${
                horizontal ? 'pr-module--horizontal' : ''
            } pr-module--align-${align}`}
        >
            {children}
        </div>
    );
}
