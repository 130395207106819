import './Claim.scss';

import React from 'react';
import { Module } from '../Module/Module';

interface ClaimProps {
}

export function Claim ({ }: ClaimProps) {

  return (
    <div className={`pr-claim`}>
        <h1>Share essential content via <span>sms topics</span>.</h1>
    </div>
  );
}
