import { Heading } from '../../components/Heading/Heading';
import React from 'react';
import './Landing.scss';
import { HorizontalSpacer } from '../../components/HorizontalSpacer/HorizontalSpacer';
import { Logo } from '../../components/Logo/Logo';
import { Paragraph } from '../../components/Paragraph/Paragraph';
import { Button } from '../../components/Button/Button';
import { Module } from '../../components/Module/Module';
import { Claim } from '../../components/Claim/Claim';
import Bottle from './Bottle.svg';
import Sms from './Sms.svg';
import { VerticalSpacer } from '../../components/VerticalSpacer/VerticalSpacer';


const _ = require('lodash');
const axios = require('axios').default;


class Landing extends React.Component {

  state = { width: window.innerWidth, height: window.innerHeight };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    return (
      <div className="snack-landing">

        <Module>
          <div className="snack-header sticky">
            <div className="snack-header-left">
              <Logo/>
              <HorizontalSpacer/>
              {this.state.width > 480 && <Heading text={'sms topics'} level={3} light/>}
            </div>
            <div className="snack-header-right">
              <Button primary bordered label={'Create Topic'} onClick={() => {
                window.open("https://sms-topics.com/ctop");
              }}/>
              <HorizontalSpacer/>

            </div>

          </div>
          <Module align="center">
            <Claim/>
            <Heading center level={2} light
                     text={'Create a sms topic for your cause. Invite people to join. Share breaking news with your audience max once a month via sms.'}/>
            <VerticalSpacer/>
            <VerticalSpacer/>
            <VerticalSpacer/>
            <Button primary bordered label={'Get Started'} onClick={() => {
              window.open("https://sms-topics.com/ctop");
            }}/>
            <VerticalSpacer/>
            <VerticalSpacer/>
            <Button secondary bordered label={'Topic Manager'} onClick={() => {
              window.open("https://sms-topics.com/manager");
            }}/>
            <VerticalSpacer/>
            <VerticalSpacer/>
            <VerticalSpacer/>
            <VerticalSpacer/>
          </Module>

          <VerticalSpacer/>
          <VerticalSpacer/>
          <a target="_blank" style={{color: 'white', textDecoration: 'none', opacity: 0.8, fontSize: "var(--font-size-s)"}} href={"https://sms-topics.com/imprint"}>imprint & data privacy</a>
          <VerticalSpacer/>
          <VerticalSpacer/>
        </Module>
      </div>
    );
  }
}

export default Landing;


              /*
                     <Module padded align="center" horizontal spacing={"l"}>
            <Heading center level={2}
                     text={'How it works.'}/>
          </Module>
          <Module padded  align="center" horizontal>
            <Heading light level={1} subheading={"This can be a music band, your fitness studio, a restaurant or a whole music festival."}
                     text={'1. Create your Topic.'}/>
          </Module>
          <Module padded align="center" horizontal>
            <Heading light level={1} subheading={"Build a sustainable audience. People join your Topic via their mobile number. Mobile numbers are safe & encrypted. You will not have access to them directly."}
                     text={'2. Share your Topic URL.'}/>
          </Module>
          <Module padded align="center" horizontal>
            <Heading light level={1} subheading={"Appreciate your audience trust. Only share information that is truly valuable and new."}
                     text={'3. Decide what to share.'}/>
          </Module>
          <Module padded align="center" horizontal>
            <Heading light level={1} subheading={"You can share your news via our platform once a month to keep it focused. Anybody in your list will receive a oldschool SMS. Conversion rates are way higher compared to any other marketing channel."}
                     text={'4. Share your content.'}/>
          </Module>
               */
