import React from "react";

class Imprint extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return <div className="mainContainer" style={{backgroundColor: 'white', fontSize: 12, alignSelf: 'center', padding: 20}}>



      <p style={{fontWeight: 'bold', paddingBottom: 10}}>Angaben gem&auml;&szlig; &sect; 5 TMG</p>

      <p style={{fontWeight: 'bold', paddingBottom: 10}}>Felix Melchhardt, Römerstraße 2 94032 Passau</p>


      <p  style={{fontWeight: 'bold'}}>Kontakt: Telefon: 0049-015127056266 E-Mail: felix.melchhardt (at) gmail.com</p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>Haftungsausschluss</p>

      <p>Haftung f&uuml;r Inhalte: Die Inhalte unserer Seiten wurden mit gr&ouml;&szlig;ter Sorgfalt erstellt. F&uuml;r die Richtigkeit, Vollst&auml;ndigkeit und Aktualit&auml;t der Inhalte k&ouml;nnen wir jedoch keine Gew&auml;hr &uuml;bernehmen. Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>

      <p>Haftung f&uuml;r Links: Unser Angebot enth&auml;lt Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>Urheberrecht </p>

      <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>Datenschutz </p>

      <p> Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten m&ouml;glich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit m&ouml;glich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdr&uuml;ckliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich. Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten durch Dritte zur &Uuml;bersendung von nicht ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdr&uuml;cklich widersprochen. Die Betreiber der Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>Datenschutzerkl&auml;rung</p>

      <p>Diese Datenschutzerkl&auml;rung kl&auml;rt Sie &uuml;ber die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz &#8222;Daten&ldquo;) im Rahmen der Erbringung unserer Leistungen sowie innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepr&auml;senzen, wie z.B. unser Social Media Profile auf (nachfolgend gemeinsam bezeichnet als &#8222;Onlineangebot&ldquo;). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. &#8222;Verarbeitung&ldquo; oder &#8222;Verantwortlicher&ldquo; verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).</p>

      <p> Verantwortlicher sms-topics 94032 Passau Römerstraße 2 Deutschland felix.melchhardt@gmail.com</p>

      <p>https://sms-topics.com/imprint</p>

      <p>Arten der verarbeiteten Daten - Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen). - Kontaktdaten (z.B., E-Mail, Telefonnummern). - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos). - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten). - Meta-/Kommunikationsdaten (z.B., Ger&auml;te-Informationen, IP-Adressen).</p>

      <p>Kategorien betroffener Personen Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als &#8222;Nutzer&ldquo;).</p>

      <p>Zweck der Verarbeitung - Zurverf&uuml;gungstellung des Onlineangebotes, seiner Funktionen und Inhalte. - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern. - Sicherheitsma&szlig;nahmen. - Reichweitenmessung/Marketing</p>

      <p>Verwendete Begrifflichkeiten &#8222;Personenbezogene Daten&ldquo; sind alle Informationen, die sich auf eine identifizierte oder identifizierbare nat&uuml;rliche Person (im Folgenden &#8222;betroffene Person&ldquo;) beziehen; als identifizierbar wird eine nat&uuml;rliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identit&auml;t dieser nat&uuml;rlichen Person sind.</p>

      <p>&#8222;Verarbeitung&ldquo; ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgef&uuml;hrte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten.</p>

      <p>&#8222;Pseudonymisierung&ldquo; die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung zus&auml;tzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden k&ouml;nnen, sofern diese zus&auml;tzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Ma&szlig;nahmen unterliegen, die gew&auml;hrleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren nat&uuml;rlichen Person zugewiesen werden.</p>

      <p>&#8222;Profiling&ldquo; jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte pers&ouml;nliche Aspekte, die sich auf eine nat&uuml;rliche Person beziehen, zu bewerten, insbesondere um Aspekte bez&uuml;glich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, pers&ouml;nliche Vorlieben, Interessen, Zuverl&auml;ssigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser nat&uuml;rlichen Person zu analysieren oder vorherzusagen.</p>

      <p>Als &#8222;Verantwortlicher&ldquo; wird die nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.</p>

      <p>&#8222;Auftragsverarbeiter&ldquo; eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.</p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>Ma&szlig;gebliche Rechtsgrundlagen </p>

      <p>Nach Ma&szlig;gabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. F&uuml;r Nutzer aus dem Geltungsbereich der Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt, sofern die Rechtsgrundlage in der Datenschutzerkl&auml;rung nicht genannt wird, Folgendes: Die Rechtsgrundlage f&uuml;r die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO; Die Rechtsgrundlage f&uuml;r die Verarbeitung zur Erf&uuml;llung unserer Leistungen und Durchf&uuml;hrung vertraglicher Ma&szlig;nahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO; Die Rechtsgrundlage f&uuml;r die Verarbeitung zur Erf&uuml;llung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO; F&uuml;r den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen nat&uuml;rlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage. Die Rechtsgrundlage f&uuml;r die erforderliche Verarbeitung zur Wahrnehmung einer Aufgabe, die im &ouml;ffentlichen Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt, die dem Verantwortlichen &uuml;bertragen wurde ist Art. 6 Abs. 1 lit. e DSGVO. Die Rechtsgrundlage f&uuml;r die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Die Verarbeitung von Daten zu anderen Zwecken als denen, zu denen sie ehoben wurden, bestimmt sich nach den Vorgaben des Art 6 Abs. 4 DSGVO. Die Verarbeitung von besonderen Kategorien von Daten (entsprechend Art. 9 Abs. 1 DSGVO) bestimmt sich nach den Vorgaben des Art. 9 Abs. 2 DSGVO.</p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>Sicherheitsma&szlig;nahmen</p>


      <p>Wir treffen nach Ma&szlig;gabe der gesetzlichen Vorgabenunter Ber&uuml;cksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umst&auml;nde und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos f&uuml;r die Rechte und Freiheiten nat&uuml;rlicher Personen, geeignete technische und organisatorische Ma&szlig;nahmen, um ein dem Risiko angemessenes Schutzniveau zu gew&auml;hrleisten.</p>

      <p>Zu den Ma&szlig;nahmen geh&ouml;ren insbesondere die Sicherung der Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit von Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verf&uuml;gbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, L&ouml;schung von Daten und Reaktion auf Gef&auml;hrdung der Daten gew&auml;hrleisten. Ferner ber&uuml;cksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.</p>

      <p>Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen und Dritten Sofern wir im Rahmen unserer Verarbeitung Daten gegen&uuml;ber anderen Personen und Unternehmen (Auftragsverarbeitern, gemeinsam Verantwortlichen oder Dritten) offenbaren, sie an diese &uuml;bermitteln oder ihnen sonst Zugriff auf die Daten gew&auml;hren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine &Uuml;bermittlung der Daten an Dritte, wie an Zahlungsdienstleister, zur Vertragserf&uuml;llung erforderlich ist), Nutzer eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.).</p>

      <p>Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe offenbaren, &uuml;bermitteln oder ihnen sonst den Zugriff gew&auml;hren, erfolgt dies insbesondere zu administrativen Zwecken als berechtigtes Interesse und dar&uuml;berhinausgehend auf einer den gesetzlichen Vorgaben entsprechenden Grundlage.</p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>&Uuml;bermittlungen in Drittl&auml;nder </p>


      <p>Sofern wir Daten in einem Drittland (d.h. au&szlig;erhalb der Europ&auml;ischen Union (EU), des Europ&auml;ischen Wirtschaftsraums (EWR) oder der Schweizer Eidgenossenschaft) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw. &Uuml;bermittlung von Daten an andere Personen oder Unternehmen geschieht, erfolgt dies nur, wenn es zur Erf&uuml;llung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland nur beim Vorliegen der gesetzlichen Voraussetzungen. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. f&uuml;r die USA durch das &#8222;Privacy Shield&ldquo;) oder Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen.</p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>Rechte der betroffenen Personen  </p>


      <p>Sie haben das Recht, eine Best&auml;tigung dar&uuml;ber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft &uuml;ber diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.</p>

      <p>Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die Vervollst&auml;ndigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.</p>

      <p>Sie haben nach Ma&szlig;gabe der gesetzlichen Vorgaben das Recht zu verlangen, dass betreffende Daten unverz&uuml;glich gel&ouml;scht werden, bzw. alternativ nach Ma&szlig;gabe der gesetzlichen Vorgaben eine Einschr&auml;nkung der Verarbeitung der Daten zu verlangen.</p>

      <p>Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Ma&szlig;gabe der gesetzlichen Vorgaben zu erhalten und deren &Uuml;bermittlung an andere Verantwortliche zu fordern.</p>

      <p>Sie haben ferner nach Ma&szlig;gabe der gesetzlichen Vorgaben das Recht, eine Beschwerde bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde einzureichen.</p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>Widerrufsrecht</p>


      <p>Sie haben das Recht, erteilte Einwilligungen mit Wirkung f&uuml;r die Zukunft zu widerrufen.</p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>Widerspruchsrecht </p>

      <p> Sie k&ouml;nnen der k&uuml;nftigen Verarbeitung der Sie betreffenden Daten nach Ma&szlig;gabe der gesetzlichen Vorgaben jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung f&uuml;r Zwecke der Direktwerbung erfolgen.</p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>Cookies und Widerspruchsrecht bei Direktwerbung  </p>


      <p>Als &#8222;Cookies&ldquo; werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb der Cookies k&ouml;nnen unterschiedliche Angaben gespeichert werden. Ein Cookie dient prim&auml;r dazu, die Angaben zu einem Nutzer (bzw. dem Ger&auml;t auf dem das Cookie gespeichert ist) w&auml;hrend oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Als tempor&auml;re Cookies, bzw. &#8222;Session-Cookies&ldquo; oder &#8222;transiente Cookies&ldquo;, werden Cookies bezeichnet, die gel&ouml;scht werden, nachdem ein Nutzer ein Onlineangebot verl&auml;sst und seinen Browser schlie&szlig;t. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein Login-Status gespeichert werden. Als &#8222;permanent&ldquo; oder &#8222;persistent&ldquo; werden Cookies bezeichnet, die auch nach dem Schlie&szlig;en des Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso k&ouml;nnen in einem solchen Cookie die Interessen der Nutzer gespeichert werden, die f&uuml;r Reichweitenmessung oder Marketingzwecke verwendet werden. Als &#8222;Third-Party-Cookie&ldquo; werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht man von &#8222;First-Party Cookies&ldquo;).</p>

      <p>Wir k&ouml;nnen tempor&auml;re und permanente Cookies einsetzen und kl&auml;ren hier&uuml;ber im Rahmen unserer Datenschutzerkl&auml;rung auf.</p>

      <p>Falls die Nutzer nicht m&ouml;chten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies k&ouml;nnen in den Systemeinstellungen des Browsers gel&ouml;scht werden. Der Ausschluss von Cookies kann zu Funktionseinschr&auml;nkungen dieses Onlineangebotes f&uuml;hren.</p>

      <p>Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, &uuml;ber die US-amerikanische Seite http://www.aboutads.info/choices/ oder die EU-Seite http://www.youronlinechoices.com/ erkl&auml;rt werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt werden k&ouml;nnen.</p>

      <p>L&ouml;schung von Daten Die von uns verarbeiteten Daten werden nach Ma&szlig;gabe der gesetzlichen Vorgaben gel&ouml;scht oder in ihrer Verarbeitung eingeschr&auml;nkt. Sofern nicht im Rahmen dieser Datenschutzerkl&auml;rung ausdr&uuml;cklich angegeben, werden die bei uns gespeicherten Daten gel&ouml;scht, sobald sie f&uuml;r ihre Zweckbestimmung nicht mehr erforderlich sind und der L&ouml;schung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>

      <p>Sofern die Daten nicht gel&ouml;scht werden, weil sie f&uuml;r andere und gesetzlich zul&auml;ssige Zwecke erforderlich sind, wird deren Verarbeitung eingeschr&auml;nkt. D.h. die Daten werden gesperrt und nicht f&uuml;r andere Zwecke verarbeitet. Das gilt z.B. f&uuml;r Daten, die aus handels- oder steuerrechtlichen Gr&uuml;nden aufbewahrt werden m&uuml;ssen.</p>

      <p>&Auml;nderungen und Aktualisierungen der Datenschutzerkl&auml;rung Wir bitten Sie sich regelm&auml;&szlig;ig &uuml;ber den Inhalt unserer Datenschutzerkl&auml;rung zu informieren. Wir passen die Datenschutzerkl&auml;rung an, sobald die &Auml;nderungen der von uns durchgef&uuml;hrten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die &Auml;nderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.</p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>Onlinepr&auml;senzen in sozialen Medien </p>


      <p> Wir unterhalten Onlinepr&auml;senzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern kommunizieren und sie dort &uuml;ber unsere Leistungen informieren zu k&ouml;nnen.</p>

      <p>Wir weisen darauf hin, dass dabei Daten der Nutzer au&szlig;erhalb des Raumes der Europ&auml;ischen Union verarbeitet werden k&ouml;nnen. Hierdurch k&ouml;nnen sich f&uuml;r die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer erschwert werden k&ouml;nnte. Im Hinblick auf US-Anbieter die unter dem Privacy-Shield zertifiziert sind, weisen wir darauf hin, dass sie sich damit verpflichten, die Datenschutzstandards der EU einzuhalten.</p>

      <p>Ferner werden die Daten der Nutzer im Regelfall f&uuml;r Marktforschungs- und Werbezwecke verarbeitet. So k&ouml;nnen z.B. aus dem Nutzungsverhalten und sich daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile k&ouml;nnen wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und au&szlig;erhalb der Plattformen zu schalten, die mutma&szlig;lich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner k&ouml;nnen in den Nutzungsprofilen auch Daten unabh&auml;ngig der von den Nutzern verwendeten Ger&auml;te gespeichert werden (insbesondere wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).</p>
      <p style={{fontWeight: 'bold', paddingTop: 10}}>Onlinepr&auml;senzen in sozialen Medien </p>


      <p>Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage unserer berechtigten Interessen an einer effektiven Information der Nutzer und Kommunikation mit den Nutzern gem. Art. 6 Abs. 1 lit. f. DSGVO. Falls die Nutzer von den jeweiligen Anbietern der Plattformen um eine Einwilligung in die vorbeschriebene Datenverarbeitung gebeten werden, ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.</p>

      <p>F&uuml;r eine detaillierte Darstellung der jeweiligen Verarbeitungen und der Widerspruchsm&ouml;glichkeiten (Opt-Out), verweisen wir auf die nachfolgend verlinkten Angaben der Anbieter.</p>

      <p>Auch im Fall von Auskunftsanfragen und der Geltendmachung von Nutzerrechten, weisen wir darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden k&ouml;nnen. Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und k&ouml;nnen direkt entsprechende Ma&szlig;nahmen ergreifen und Ausk&uuml;nfte geben. Sollten Sie dennoch Hilfe ben&ouml;tigen, dann k&ouml;nnen Sie sich an uns wenden.</p>

      <p>- Facebook, -Seiten, -Gruppen, (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) auf Grundlage einer Vereinbarung &uuml;ber gemeinsame Verarbeitung personenbezogener Daten - Datenschutzerkl&auml;rung: https://www.facebook.com/about/privacy/, speziell f&uuml;r Seiten: https://www.facebook.com/legal/terms/information_about_page_insights_data , Opt-Out: https://www.facebook.com/settings?tab=ads und http://www.youronlinechoices.com, Privacy Shield: https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active.</p>

      <p>- Google/ YouTube (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) &ndash; Datenschutzerkl&auml;rung: https://policies.google.com/privacy, Opt-Out: https://adssettings.google.com/authenticated, Privacy Shield: https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active.</p>

      <p>- Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA) &ndash; Datenschutzerkl&auml;rung/ Opt-Out: http://instagram.com/about/legal/privacy/.</p>

      <p>- Twitter (Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA) - Datenschutzerkl&auml;rung: https://twitter.com/de/privacy, Opt-Out: https://twitter.com/personalization, Privacy Shield: https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active.</p>

      <p>- Pinterest (Pinterest Inc., 635 High Street, Palo Alto, CA, 94301, USA) &ndash; Datenschutzerkl&auml;rung/ Opt-Out: https://about.pinterest.com/de/privacy-policy.</p>

      <p>- LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Irland) - Datenschutzerkl&auml;rung https://www.linkedin.com/legal/privacy-policy , Opt-Out: https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out, Privacy Shield: https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active.</p>

      <p>- Xing (XING AG, Dammtorstra&szlig;e 29-32, 20354 Hamburg, Deutschland) - Datenschutzerkl&auml;rung/ Opt-Out: https://privacy.xing.com/de/datenschutzerklaerung.</p>

      <p>- Wakalet (Wakelet Limited, 76 Quay Street, Manchester, M3 4PR, United Kingdom) - Datenschutzerkl&auml;rung/ Opt-Out: https://wakelet.com/privacy.html.</p>

      <p>- Soundcloud (SoundCloud Limited, Rheinsberger Str. 76/77, 10115 Berlin, Deutschland) - Datenschutzerkl&auml;rung/ Opt-Out: https://soundcloud.com/pages/privacy.</p>

      <p></p>

      <p style={{fontWeight: 'bold', paddingTop: 10}}>Einbindung von Diensten und Inhalten Dritter</p>

      <p> Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als &ldquo;Inhalte&rdquo;).</p>

      <p>Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden k&ouml;nnten. Die IP-Adresse ist damit f&uuml;r die Darstellung dieser Inhalte erforderlich. Wir bem&uuml;hen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter k&ouml;nnen ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch als &quot;Web Beacons&quot; bezeichnet) f&uuml;r statistische oder Marketingzwecke verwenden. Durch die &quot;Pixel-Tags&quot; k&ouml;nnen Informationen, wie der Besucherverkehr auf den Seiten dieser Website ausgewertet werden. Die pseudonymen Informationen k&ouml;nnen ferner in Cookies auf dem Ger&auml;t der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden werden.</p>

      <p></p>

      <p>Youtube</p>

      <p> Wir binden die Videos der Plattform &ldquo;YouTube&rdquo; des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerkl&auml;rung: https://www.google.com/policies/privacy/, Opt-Out: https://adssettings.google.com/authenticated.</p>

      <p></p>

      <p >Verwendung von Facebook Social Plugins</p>

      <p> Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Social Plugins (&quot;Plugins&quot;) des sozialen Netzwerkes facebook.com, welches von der Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird (&quot;Facebook&quot;). Hierzu k&ouml;nnen z.B. Inhalte wie Bilder, Videos oder Texte und Schaltfl&auml;chen geh&ouml;ren, mit denen Nutzer Inhalte dieses Onlineangebotes innerhalb von Facebook teilen k&ouml;nnen. Die Liste und das Aussehen der Facebook Social Plugins kann hier eingesehen werden: https://developers.facebook.com/docs/plugins/.</p>

      <p>Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europ&auml;ische Datenschutzrecht einzuhalten (https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active).</p>

      <p>Wenn ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein solches Plugin enth&auml;lt, baut sein Ger&auml;t eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook direkt an das Ger&auml;t des Nutzers &uuml;bermittelt und von diesem in das Onlineangebot eingebunden. Dabei k&ouml;nnen aus den verarbeiteten Daten Nutzungsprofile der Nutzer erstellt werden. Wir haben daher keinen Einfluss auf den Umfang der Daten, die Facebook mit Hilfe dieses Plugins erhebt und informiert die Nutzer daher entsprechend unserem Kenntnisstand.</p>

      <p>Durch die Einbindung der Plugins erh&auml;lt Facebook die Information, dass ein Nutzer die entsprechende Seite des Onlineangebotes aufgerufen hat. Ist der Nutzer bei Facebook eingeloggt, kann Facebook den Besuch seinem Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum Beispiel den Like Button bet&auml;tigen oder einen Kommentar abgeben, wird die entsprechende Information von Ihrem Ger&auml;t direkt an Facebook &uuml;bermittelt und dort gespeichert. Falls ein Nutzer kein Mitglied von Facebook ist, besteht trotzdem die M&ouml;glichkeit, dass Facebook seine IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in Deutschland nur eine anonymisierte IP-Adresse gespeichert.</p>

      <p>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie die diesbez&uuml;glichen Rechte und Einstellungsm&ouml;glichkeiten zum Schutz der Privatsph&auml;re der Nutzer, k&ouml;nnen diese den Datenschutzhinweisen von Facebook entnehmen: https://www.facebook.com/about/privacy/.</p>

      <p>Wenn ein Nutzer Facebookmitglied ist und nicht m&ouml;chte, dass Facebook &uuml;ber dieses Onlineangebot Daten &uuml;ber ihn sammelt und mit seinen bei Facebook gespeicherten Mitgliedsdaten verkn&uuml;pft, muss er sich vor der Nutzung unseres Onlineangebotes bei Facebook ausloggen und seine Cookies l&ouml;schen. Weitere Einstellungen und Widerspr&uuml;che zur Nutzung von Daten f&uuml;r Werbezwecke, sind innerhalb der Facebook-Profileinstellungen m&ouml;glich: https://www.facebook.com/settings?tab=ads oder &uuml;ber die US-amerikanische Seite http://www.aboutads.info/choices/ oder die EU-Seite http://www.youronlinechoices.com/. Die Einstellungen erfolgen plattformunabh&auml;ngig, d.h. sie werden f&uuml;r alle Ger&auml;te, wie Desktopcomputer oder mobile Ger&auml;te &uuml;bernommen.</p>

      <p>Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke</p>


      <p style={{fontWeight: 'bold', paddingTop: 10}}>Allgemeine Geschäftsbedingungen der sms-topics</p>


      <br />
      <p style={{fontWeight: 'bold', paddingTop: 10}}>§1 Geltung gegenüber Unternehmern und Begriffsdefinitionen</p>
      (1) Die nachfolgenden Allgemeinen Geschäftbedingungen gelten für alle Lieferungen zwischen uns und einem Verbraucher
      in ihrer zum Zeitpunkt der Bestellung gültigen Fassung.<br />
      <br />
      Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder
      ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können (§ 13 BGB).
      <br />

      <br />
      <p style={{fontWeight: 'bold', paddingTop: 10}}>§2 Zustandekommen eines Vertrages, Speicherung des Vertragstextes</p>
      (1) Die folgenden Regelungen über den Vertragsabschluss gelten für Bestellungen über unseren Internetshop oder unsere offizielle website.<br />
      <br />
      <b>(2) Im Falle des Vertragsschlusses kommt der Vertrag mit<br />

        <div style={{fontWeight: 'bold', paddingTop: 10}} >
          <br />
          sms-topics<br />
          Römerstraße 2<br />
          D-94032 Passau<br />
          <br />
        </div>
        zustande.<br /></b>
      <br />
      (3) Die Präsentation der Waren in unserem Internetshop stellen kein rechtlich bindendes Vertragsangebot unsererseits dar,
      sondern sind nur eine unverbindliche Aufforderungen an den Verbraucher, Waren zu bestellen. Mit der Bestellung der gewünschten Ware gibt der Verbraucher ein für
      ihn verbindliches Angebot auf Abschluss eines Kaufvertrages ab.<br />
      (4) Bei Eingang einer Bestellung in unserem Internetshop gelten folgende Regelungen:
      Der Verbraucher gibt ein bindendes Vertragsangebot ab, indem er die in unserem Internetshop vorgesehene Bestellprozedur erfolgreich durchläuft.<br />
      <br />
      Die Bestellung erfolgt in folgenden Schritten:<br />
      <br />
      <div>

        1) Auswahl der gewünschten Ware<br />

        2) Bestätigen durch Anklicken der Buttons „Bestellen“ <br />

        3) Prüfung der Angaben im Warenkorb<br />

        4) Betätigung des Buttons „zur Kasse“ <br />

        5) Anmeldung im Internetshop nach Registrierung und Eingabe der Anmelderangaben (E-Mail-Adresse und Passwort). <br />

        6) Nochmalige Prüfung bzw. Berichtigung der jeweiligen eingegebenen Daten.<br />

        7) Verbindliche Absendung der Bestellung durch Anklicken des Buttons „kostenpflichtig bestellen“ bzw. „kaufen“<br />

        <br />
      </div>
      Der Verbraucher kann vor dem verbindlichen Absenden der Bestellung durch Betätigen der in dem von ihm verwendeten Internet-Browser enthaltenen „Zurück“-Taste nach
      Kontrolle seiner Angaben wieder zu der Internetseite gelangen, auf der die Angaben des Kunden erfasst werden und Eingabefehler berichtigen bzw. durch Schließen des
      Internetbrowsers den Bestellvorgang abbrechen.
      Wir bestätigen den Eingang der Bestellung unmittelbar durch eine automatisch generierte E-Mail („Auftragsbestätigung“). Mit dieser nehmen wir Ihr Angebot an.
      <br />
      <br />
      (5) Speicherung des Vertragstextes bei Bestellungen über unseren Internetshop : Wir senden Ihnen die Bestelldaten und unsere AGB per
      E-Mail zu. Die AGB können Sie jederzeit auch unter https://www.sms-topics.dcom/imprint einsehen.

      Ihre Bestelldaten sind aus Sicherheitsgründen nicht mehr über das Internet zugänglich.
      <br />


      <br />
      <p style={{fontWeight: 'bold', paddingBottom: 10}}>§3 Preise, Versandkosten, Zahlung, Fälligkeit</p>
      (1) Die angegebenen Preise enthalten die gesetzliche Umsatzsteuer und sonstige Preisbestandteile. Hinzu kommen etwaige Versandkosten.<br />
      <br />
      (2) Der Verbraucher hat die Möglichkeit der Zahlung per
      PayPal




      .<br />
      <br />


      <br />
      <p style={{fontWeight: 'bold', paddingBottom: 10}}>§4 Lieferung</p>
      (1) Sofern wir dies in der Produktbeschreibung nicht deutlich anders angegeben haben, sind alle von uns angebotenen Artikel sofort versandfertig.
      Die Lieferung erfolgt hier spätesten innerhalb von 14 Werktagen.
      Dabei beginnt die Frist für die Lieferung im Falle der Zahlung per Vorkasse am Tag nach
      Zahlungsauftrag an die mit der Überweisung beauftragte Bank und bei allen anderen Zahlungsarten am Tag nach Vertragsschluss zu laufen.
      Fällt das Fristende auf einen Samstag, Sonntag oder gesetzlichen Feiertag am Lieferort, so endet die Frist am nächsten Werktag.
      <br /><br />

      (2) Die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung der
      verkauften Sache geht auch beim Versendungskauf erst mit der Übergabe der Sache an den Käufer auf diesen über.  <br />


      <br />
      <p style={{fontWeight: 'bold', paddingBottom: 10}}>§5 Eigentumsvorbehalt</p>
      Wir behalten uns das Eigentum an der Ware bis zur vollständigen Bezahlung des Kaufpreises vor. <br />



      <br />

      ****************************************************************************************************
      <br />
      <p style={{fontWeight: 'bold', paddingBottom: 10}}>§6 Widerrufsrecht des Kunden als Verbraucher:
      </p>

      <b>
        Widerrufsrecht für Verbraucher</b><br />
      <br />
      Verbrauchern steht ein Widerrufsrecht nach folgender Maßgabe zu, wobei Verbraucher jede natürliche Person ist, die ein Rechtsgeschäft zu Zwecken abschließt,
      die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können:

      <br />
      <br />

      <p style={{fontWeight: 'bold', paddingBottom: 10, textAlign: 'center'}}>Widerrufsbelehrung</p>
      <br />
      <b>Widerrufsrecht</b><br />
      <br />
      Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
      <br />
      <br />
      Die Widerrufsfrist beträgt vierzehn Tage, ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter,
      der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.

      <br /><br />
      Um Ihr Widerrufsrecht auszuüben, müssen Sie uns<br />
      <div style={{fontWeight: 'bold', paddingBottom: 10, marginTop: 5}}>
        sms-topics
        <br />
        Römerstraße 2<br />
        D-94032 Passau<br />
        E-Mail felix.melchhardt@gmail.com<br />
      </div>
      mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss,
      diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
      <br />
      <br />

      <b>Widerrufsfolgen</b>
      <br /><br />
      Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der
      Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als
      die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen
      ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist.
      Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben,
      es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
      <br />
      <br />

      Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben,
      dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
      <br />
      <br />

      Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den
      Widerruf dieses Vertrages unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt,
      wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.
      <br />
      <br />

      Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.<br />
      <br />


      <b>Ende der Widerrufsbelehrung</b>
      <br />
      <br />
      ****************************************************************************************************

      <br />





      <p style={{fontWeight: 'bold', paddingBottom: 10}}>§7 Widerrufsformular</p>

      <p style={{fontWeight: 'bold', paddingBottom: 10}}>Muster-Widerrufsformular</p>
      (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)<br />
      <div style={{fontWeight: 'bold', paddingBottom: 10}}>
        An :<br />
        sms-topics<br />
        Römerstraße 2<br />
        D-94032 Passau<br />
        E-Mail felix.melchhardt@gmail.com<br />
        <br />

        Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)<br />
        <br />
        _____________________________________________________<br />
        <br />
        Bestellt am (*)/erhalten am (*)<br />
        <br />
        __________________<br />
        <br />
        Name des/der Verbraucher(s)<br />
        <br />
        _____________________________________________________<br />
        <br />
        Anschrift des/der Verbraucher(s)<br />
        <br />
        <br />
        _____________________________________________________<br />
        <br />
        Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)<br />
        <br />
        __________________<br />
        <br />
        Datum<br />
        <br />
        __________________<br />
        <br />
      </div>
      <p>(*) Unzutreffendes streichen.</p><br />



      <br />
      <p style={{fontWeight: 'bold', paddingBottom: 10}}>§8 Gewährleistung
      </p>

      Es gelten die gesetzlichen Gewährleistungsregelungen.
      <br />


      <br />
      <p style={{fontWeight: 'bold', paddingBottom: 10}}>§9 Vertragssprache</p>
      Als Vertragssprache steht ausschließlich Deutsch zur Verfügung.<br />

      <br />
      <br />
      <p>Stand der AGB Mai. 2020</p>
    </div>;
  }
}

export default (Imprint);
