import './Paragraph.scss';

interface ParagraphProps {
  text: string;
  bold?: boolean;
  white?: boolean;
  center?: boolean;
  transparent?: boolean;
  light?: boolean;
  tiny?: boolean;
  success?: boolean;
  error?: boolean;

}

export function Paragraph ({ text, bold, error, white, center, success, transparent, light, tiny }: ParagraphProps) {

  return (
      <p className={`pr-p ${bold ? 'pr-p--bold' : ''} ${error ? 'pr-p--error' : ''}  ${success ? 'pr-p--success' : ''} ${tiny ? 'pr-p--tiny' : ''} ${white ? 'pr-p--white' : ''} ${light ? 'pr-p--light' : ''} ${transparent ? 'pr-p--transparent' : ''} ${center ? 'pr-p--center' : ''}`}>{text}</p>
  );
}
