import './Logo.scss';

import React, { SyntheticEvent } from 'react';

interface LogoProps {
  onClick?: (event: SyntheticEvent) => any;
}

export function Logo({ onClick }: LogoProps) {
  return (
    <div
      className="pr-logo"
      onClick={event => typeof onClick === 'function' && onClick(event)}
    >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512.002 512.002"
          xmlSpace="preserve"
        >
          <path fill="var(--primary)" d="M342.379 115.985c-82.601 0-125.149 60.775-159.457 131.349a25.833 25.833 0 0 1-1.067 2.202c-7.544 15.637-14.703 31.732-21.816 47.725-11.328 25.463-22.215 49.943-34.289 71.452 25.835-7.679 51.173-13.791 73.122-13.791 43.475 0 72.292 6.712 97.717 12.634 22.849 5.324 44.428 10.35 75.743 10.35 32.765 0 139.667-9.017 139.667-125.04.001-81.88-87.704-136.881-169.62-136.881zm-13.756 185.61c-4.594 4.612-10.951 7.248-17.461 7.248-6.505 0-12.881-2.637-17.476-7.248-4.597-4.596-7.233-10.954-7.233-17.461s2.636-12.883 7.233-17.477c4.594-4.596 10.971-7.231 17.476-7.231 6.508 0 12.867 2.636 17.461 7.231 4.614 4.594 7.248 10.971 7.248 17.477s-2.634 12.866-7.248 17.461zm82.346-65.924c-4.611 4.596-10.971 7.248-17.475 7.248-6.505 0-12.881-2.652-17.476-7.248-4.597-4.594-7.233-10.954-7.233-17.461 0-6.507 2.636-12.881 7.233-17.477 4.594-4.596 10.971-7.231 17.476-7.231s12.867 2.636 17.475 7.231c4.597 4.596 7.233 10.971 7.233 17.477 0 6.493-2.636 12.867-7.233 17.461zM0 374.364c0 15.581 2.349 18.823 2.448 18.953.058.028 3.581 2.698 20.905 2.698.026 0 .038-.002.064 0 38.636-.061 63.119-55.107 91.469-118.838 1.698-3.82 3.416-7.686 5.161-11.58C22.028 282.145 0 357.982 0 374.364z" />
        </svg>
    </div>
  );
}
