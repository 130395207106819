import './Label.scss';

import React from 'react';

interface LabelProps {
  text: string;
}

export function Label ({ text }: LabelProps) {

  return (
      <span className="pr-label">{text}</span>
  );
}
