import './SendMessage.scss';
import * as React from 'react';
import { TextInput } from '../TextInput/TextInput';
import { Button } from '../Button/Button';
import { Label } from '../Label/Label';
import { Heading } from '../Heading/Heading';
import { Paragraph } from '../Paragraph/Paragraph';
import { VerticalSpacer } from '../VerticalSpacer/VerticalSpacer';
import { API } from '../../config/config';
import axios from 'axios';
import { useState } from 'react';

interface SendMessageProps {
  topic: any;
}

export function SendMessage({ topic }: SendMessageProps) {

  const [text, setText] = useState(null);
  const [sent, setSent] = useState(false);
  const [err, setErr] = useState(null);


  const send = () => {
    axios.post(API + '/send/sms', {
      topicId: topic._id, text: 'Message from ' + topic.topic + ': ' + text
    })
      .then((response: any) => {
        if (response.status === 200) {
          setSent(true);
        }

        if (response.data.err) {
          setErr(response.data.err);
        }
      })
      .catch(function(error: any) {
        console.log(error);
      });
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center'
    }}><Heading light center level={3}
                text={topic.topic}/>

      <VerticalSpacer/>
      {!sent && <Label text={'SMS Message to Audience'}/>}
      {!sent && <TextInput maxlength={140} rows={4} style={{ width: '70vw' }} onChange={(e) => {
        setText((e.target as HTMLInputElement).value);
      }}
                           placeholder={'Only send breaking news. Max 140 characters. e.g. The festival will be cancelled because it\'s heavily raining. '}/>}
      <VerticalSpacer/>
      <VerticalSpacer/>

      {!err && sent && <Paragraph center
                                  text={'Message successfully sent. You can send the next message in one month. Remember: Only focus on the essentials.'}
                                  success bold/>}

      {err && <Paragraph center text={err} error bold/>}

      <VerticalSpacer/>
      <VerticalSpacer/> <VerticalSpacer/>
      {!sent && <Button disabled={!text || text === '' || sent} primary onClick={() => {
        send();
      }}
                        label={'Send Message (Max. 1 per Month)'}/>}
      <VerticalSpacer/>
      <VerticalSpacer/>
      <Button secondary label={'Visit your URL to share'} onClick={() => {
        window.open('https://sms-topics.com/t/' + topic.shortcut.toString());
      }}/>

      <VerticalSpacer/>
      <VerticalSpacer/>
      <VerticalSpacer/>
      <VerticalSpacer/>
    </div>);
}
