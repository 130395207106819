import './Button.scss';

import React, { SyntheticEvent } from 'react';

interface ButtonProps {
  disabled?: boolean;
  fancy?: boolean;
  inlineLink?: boolean;
  label?: string;
  link?: boolean;
  light?: boolean;
  onClick?: (event: SyntheticEvent) => any;
  primary?: boolean;
  secondary?: boolean;
  title?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  [props: string]: any;
  bordered?: boolean;
  onDark?: boolean;
}

export function Button({
  disabled,
  fancy,
  inlineLink,
  label,
  light,
  link,
  onClick,
  primary,
  secondary,
  onDark,
  title,
  type, bordered, ...rest
}: ButtonProps) {
  type = type || 'button';

  return (
    <button
      className={`pr-button ${!!primary ? 'pr-button--primary' : ''} ${
        !!secondary ? 'pr-button--secondary' : ''
      } ${!!link ? 'pr-button--link' : ''} ${
        !!inlineLink ? 'pr-button--inline-link' : ''
      } ${!!fancy ? 'pr-button--fancy' : ''} ${
        !!light ? 'pr-button--light' : ''
      } ${
        !!bordered ? 'pr-button--bordered' : ''
        }
        ${
        !!onDark ? 'pr-button--ondark' : ''
        }`}
      disabled={disabled}
      onClick={onClick}
      title={title}
      type={type}
      {...rest}
    >
      <span>{label}</span>
    </button>
  );
}
