import './Heading.scss';

import React from 'react';

interface HeadingProps {
  level?: number;
  light?: boolean;
  center?: boolean;
  subheading?: string;
  subsubheading?: string;
  text: string;
  bordered?: boolean;
  fancy?: boolean
}

export function Heading ({ level = 1, light, subheading, text, center = false, subsubheading, bordered, fancy }: HeadingProps) {
  const Tag: any = `h${level}`;

  return (
    <Tag style={{maxWidth: '60ch'}} className={`pr-heading ${light ? 'pr-heading--light' : ''} ${bordered ? 'pr-heading--bordered' : ''} ${center ? 'pr-heading--centered' : ''}`}>
      {text}
      {subheading && <span className="pr-subheading">{subheading}</span>}
      {subsubheading && <span className="pr-subsubheading">{subsubheading}</span>}
    </Tag>
  );
}
