import './TextInput.scss';

import React, { SyntheticEvent } from 'react';

interface TextInputProps {
  disabled?: boolean;
  id?: string;
  onChange?: (event: SyntheticEvent) => any;
  placeholder?: string;
  rows?: number;
  type?: string;
  value?: string | number;
  [prop: string]: any;
  maxlength?: number
}

export function TextInput({
  disabled,
  id,
  onChange,
  placeholder,
  rows,
  type,
  maxlength,
  value,
  ...rest
}: TextInputProps) {
  return (
    <div className="pr-text-input">
      {(!rows || rows === 1) && (
        <input
          className="pr-text-input__input"
          disabled={disabled}
          id={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type || 'text'}
          {...rest}
        />
      )}
      {rows && rows > 1 && (
        <textarea
          maxLength={maxlength ? maxlength : 2000}
          className="pr-text-input__input"
          disabled={disabled}
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          rows={rows}
          {...rest}
        />
      )}
    </div>
  );
}
