import './Toggle.scss';

import React, {SyntheticEvent} from 'react';

interface ToggleProps {
    onChange?: (event: SyntheticEvent) => any;
    labelTrue?: string;
    labelFalse?: string;
    defaultChecked?: boolean;
    [prop: string]: any;
    checked?: boolean;
}

export function Toggle({
                           onChange,
                           labelTrue,
                           labelFalse,
                           defaultChecked,
                           checked,
                           ...rest
                       }: ToggleProps) {

    return (
        <label className="switch switch-light">
            <input className="switch-input" type="checkbox" onChange={onChange} defaultChecked={defaultChecked} checked={checked}/>
            <span className="switch-label" data-on={labelTrue ? labelTrue : 'Yes'} data-off={labelFalse ? labelFalse : 'No'}/>
            <span className="switch-handle"/>
        </label>
    );
}
