import React  from 'react';
import './App.scss';
import { mount, route } from 'navi';
import { Router } from 'react-navi';
import './styles/_utilities.scss';
import Landing from './pages/landing/Landing';
import CreateTopic from './pages/createTopic/CreateTopic';
import Subscribe from './pages/subscribe/Subscribe';
import Send from './pages/send/Send';
import Unsubscribe from './pages/unsubscribe/Unsubscribe';
import Manager from './pages/manager/Manager';
import Imprint from './pages/Imprint';

const routes = mount({
  '/': route(req => {
    return {
      view:
        <Landing/>
    };
  }),
  '/manager': route(req => {
    return {
      view:
        <Manager/>
    };
  }),
  '/ctop': route(req => {
    return {
      view:
        <CreateTopic/>
    };
  }),
    '/imprint': route(req => {
      return {
        view:
          <Imprint/>
      };
    }),
    '/t/:i': route(req => {
    return {
      view:
        <Subscribe params={req.params}/>,
    }
  }),
  '/unsubscribe': route(req => {
  return {
    view:
      <Unsubscribe/>,
  }
}),
  '/send': route(req => {
    return {
      view:
        <Send/>,
    }
  }),
});


class App extends React.Component {
  render() {
    return (
      <>
        <Router routes={routes}>
        </Router>
      </>
    );
  }

}

export default App;
