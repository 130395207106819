import { Heading } from '../../components/Heading/Heading';
import React from 'react';
import './Send.scss';
import { HorizontalSpacer } from '../../components/HorizontalSpacer/HorizontalSpacer';
import { Logo } from '../../components/Logo/Logo';
import { Paragraph } from '../../components/Paragraph/Paragraph';
import { Button } from '../../components/Button/Button';
import { Module } from '../../components/Module/Module';
import { TextInput } from '../../components/TextInput/TextInput';
import { Label } from '../../components/Label/Label';
import { VerticalSpacer } from '../../components/VerticalSpacer/VerticalSpacer';
import { Toggle } from '../../components/Toggle/Toggle';
const _ = require('lodash');
const axios = require('axios').default;


class Send extends React.Component {

  state = { width: window.innerWidth, height: window.innerHeight };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    return (
      <div className="snack-create-topic">
        <Module>
          <div className="snack-header sticky">
            <div className="snack-header-left">
              <Logo/>
              <HorizontalSpacer/>
              {this.state.width > 480 && <Heading text={'sms topics'} level={3} light/>}
            </div>
          </div>
          <Module align="center">
            <Heading center level={2} subheading={"Type in your registered Mobile Nr. You will receive a code to use for sending a SMS to your topic audience."}
                     text={'Send SMS'}/>
            <VerticalSpacer/>
            <Label text={"Your Mobile Nr."}/>
            <TextInput placeholder={"Your Mobile Nr."}/>
            <VerticalSpacer/>
            <VerticalSpacer/>
            <VerticalSpacer/>
            <Button primary label={"Get Sending Code"}/>
          </Module>
          <div className="snack-footer sticky-bottom">
            <Paragraph text={'footer'} white/>
          </div>
        </Module>
      </div>
    );
  }
}

export default Send;

